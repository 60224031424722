import { Spin } from 'antd';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { getLandingPage } from '../commons/services/landing-page';
import { ServiceNotFound } from '../components/landing-page/service-not-found';
import { Theme1 } from '../components/landing-page/theme1';
import { Theme2 } from '../components/landing-page/theme2';
import { Theme3 } from '../components/landing-page/theme3';
import { ILandingPage } from '../interfaces/landing-page';

const renderLandingPage = (landingPage: ILandingPage | undefined) => {
    if (!landingPage) {
        return <ServiceNotFound />;
    }
    switch (landingPage.themeId) {
        case 'theme1':
            return <Theme1 {...landingPage} />;
        case 'theme2':
            return <Theme2 {...landingPage} />;
        case 'theme3':
            return <Theme3 {...landingPage} />;

        default:
            return <ServiceNotFound />;
    }
};
export const LandingPage: FC = () => {
    const [landingPage, setLandingPage] = useState<ILandingPage | undefined>(undefined);
    const [loading, setLoading] = useState<boolean | undefined>(false);
    const [init, setInit] = useState<boolean | undefined>(true);

    useEffect(() => {
        // Some synchronous code.
        let isMounted = true;
        const queryParams = queryString.parse(window.location.search);
        const { id } = queryParams;
        (async () => {
            if (isMounted) {
                setLoading(true);
            }
            const _landingPage = await getLandingPage(id);
            if (isMounted) {
                setLandingPage(_landingPage);
                setLoading(false);
                setInit(false);
            }
        })();

        return () => {
            // Component unmount code.
            isMounted = false;
        };
    }, []);

    /**
     * load landing page data
     */

    if (init) {
        return <>{loading && <Spin size="large" />}</>;
    } else {
        return (
            <>
                {loading && <Spin size="large" />}
                {renderLandingPage(landingPage)}
            </>
        );
    }
};
