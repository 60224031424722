import axios from 'axios';
import { ILandingPage } from '../../interfaces/landing-page';
import { IService } from '../../interfaces/service';

const axiosInstance = axios.create();
export const getLandingPage = async (id: string | (string | null)[] | null): Promise<ILandingPage | undefined> => {
    if (!id) {
        return;
    }
    try {
        const { data: landingPageData } = await axiosInstance.get<ILandingPage>(`/data/landing-pages/${id}/data.json`);
        if (landingPageData && landingPageData.serviceId) {
            const { data: serviceData } = await axiosInstance.get<IService>(
                `/data/services/${landingPageData.serviceId}.json`
            );
            return {
                ...landingPageData,
                header: `/data/landing-pages/${id}/images/header.jpg`,
                banner1: `/data/landing-pages/${id}/images/banner.jpg`,
                ivrNumber: serviceData.ivrNumber,
                members: serviceData.members,
                footer: serviceData.footer,
            };
        }
    } catch (error) {}
};
