import { Image, Layout, Row, Typography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';
import React from 'react';
import styled from 'styled-components';
import { ILandingPage } from '../../interfaces/landing-page';
import { CloseButton } from './button';
import { CloseButtonContainer, Content } from './layout';

const { Text } = Typography;

const MemberText: React.FunctionComponent<TextProps> = styled(Text)`
    &&& {
        color: red;
    }
`;
const FooterText: React.FunctionComponent<TextProps> = styled(Text)`
    &&& {
        font-weight: 700;
        text-align: center;
        font-size: 12px;
    }
`;

export const Theme1 = (props: ILandingPage) => {
    return (
        <>
            <Layout>
                <Content>
                    <CloseButtonContainer>
                        <CloseButton
                            onClick={() => {
                                window.open('', '_self');
                                window.close();
                            }}
                        />
                    </CloseButtonContainer>
                    <Row justify="center">
                        <Image preview={false} src={props.header} />
                    </Row>
                    <Row justify="center">
                        <MemberText>{`จำนวนผู้เข้าไปอ่านล่าสุด ${props.members} คน`}</MemberText>
                    </Row>
                    <Row justify="center">
                        <a href={`tel:*${props.ivrNumber}`}>
                            <Image preview={false} src={props.banner1} />
                        </a>
                    </Row>
                    <Row justify="center">
                        <FooterText>{props.footer}</FooterText>
                    </Row>
                </Content>
            </Layout>
        </>
    );
};
