import { Layout, Row } from 'antd';
import styled from 'styled-components';

export const CloseButtonContainer = styled(Row)`
    justify-content: end;
    margin-bottom: -14px;
`;
const { Content: BaseContent } = Layout;
export const Content = styled(BaseContent)`
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
`;
