import { Layout, Typography } from 'antd';
import React from 'react';
import { Content } from './layout';

const { Text } = Typography;

export const ServiceNotFound = () => {
    return (
        <>
            <Layout>
                <Content>
                    <Text>ไม่พบบริการที่เลือก</Text>
                </Content>
            </Layout>
        </>
    );
};
